import mainmodel from './MainStore';
import Store from '@ice/store';

import { CurrentTaskKey, currentTaskStore } from './CurrentTaskStore';
import { CurrentDoingTaskKey, currentDoingTaskStore } from './CurrentDoingTaskStore';
import { CurrentDayOffsetKey, currentDayOffsetStore } from './CurrentDayOffsetStore';
import { SearchModeStoreKey, SearchModeStore } from './SearchModeStore';
import { SearchTextStoreKey, SearchTextStore } from './SearchTextStore';

const storeManager = new Store();
//  *（@@@？todo 使用常量 mainmodel？）* 
storeManager.registerStore('mainmodel', mainmodel);
storeManager.registerStore(CurrentTaskKey, currentTaskStore);
storeManager.registerStore(CurrentDoingTaskKey, currentDoingTaskStore);
storeManager.registerStore(CurrentDayOffsetKey, currentDayOffsetStore);
storeManager.registerStore(SearchTextStoreKey, SearchTextStore);
storeManager.registerStore(SearchModeStoreKey, SearchModeStore);

export default storeManager;