
const SearchModeStoreKey = "SearchModeStoreKey"

/**
 * 0 表示不是 search mode, 1 表示只匹配标题，2 表示匹配标题和内容
 */
const SearchModeStore = {
    searchMode: 0,

    setSearchMode(searchMode: number) {
        this.searchMode = searchMode
    },
};

export {SearchModeStoreKey, SearchModeStore}