
const SearchTextStoreKey = "SearchTextStoreKey"

const SearchTextStore = {
    searchText: "",

    setSearchText(searchText: string) {
        this.searchText = searchText
    },
};

export {SearchTextStoreKey, SearchTextStore}