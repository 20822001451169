import 'reflect-metadata'
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input, Modal, Button, message } from 'antd';
import stores from '../stores';
import { StoreModel, Task, TimeLine } from '../model/model'
import DateUtils from '../utils/DateUtils'
import Format from '../utils/Format'
import Utils from '../utils/Utils'
import { plainToClass } from "class-transformer";


const ConfigLayout: React.FC = () => {
  const { storeModel, saveConfig, importModel } = stores.useStore('mainmodel') as { storeModel: StoreModel | undefined, saveConfig: () => void, importModel: (model: StoreModel) => void };

  const [visible, setVisible] = useState(false);
  const [autoSaveGapInHour, setAutoSaveGapInHour] = useState(0);

  useEffect(() => {
    if (storeModel !== undefined) {
      setAutoSaveGapInHour(storeModel.config.autoSaveGapInHour)
    }
  }, [visible])

  function handleOk(event: any) {
    if (storeModel === undefined) {
      return
    }

    storeModel.config.autoSaveGapInHour = autoSaveGapInHour
    saveConfig()
    setVisible(false)
  }

  function handleCancel(event: any) {
    setVisible(false)
  }

  function download() {
    storeModel?.download()
  }

  function upload(e: any) {
    let file = e.target.files[0];
    // console.log(file)

    let reader = new FileReader();
    reader.onload = function (this) {
      let uploadContent = this.result
      if (typeof uploadContent === 'string') {
        Modal.confirm({
          title: '确定要导入嘛？',
          content: `导入会覆盖当前所有任务`,
          onOk() {

            // 不提示了，就是存！
            download()
            importModel(plainToClass(StoreModel, JSON.parse(uploadContent as string)))

            // Modal.confirm({
            //   title: '是否需要先下载当前任务？',
            //   content: ``,
            //   onOk() {
            //     download()
            //     importModel(plainToClass(StoreModel, JSON.parse(uploadContent as string)))
            //   },
            //   onCancel() {
            //     importModel(plainToClass(StoreModel, JSON.parse(uploadContent as string)))
            //   }
            // });
          },
        })
      } else {
        message.error("导入文件有问题，导入失败")
      }
    }
    //读取文件内容
    reader.readAsText(file);
  }

  return (
    <span>
      <Button type="primary" size="small" onClick={(e) => setVisible(true)}>
        设置
        </Button>
      <Modal
        title="设置"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <Button type="primary" size="small" onClick={(e) => download()}>导出</Button>
          <input style={{ width: "100px", marginRight: "20px" }} type="file" id="upload-file" multiple onChange={(event) => upload(event)} />
        </div>

        <Input style={{ width: '170px', marginTop: "15px" }} value={autoSaveGapInHour} placeholder="-1为关闭" prefix="自动保存间隔" suffix="h" onChange={(event) => setAutoSaveGapInHour(Number(event.target.value))} />
      </Modal>
    </span>
  );
}

export default ConfigLayout;
