class Format {
    public static formatTimeInMs(timeMs: number) {
        let date = new Date(timeMs);//如果date为13位不需要乘1000
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()) + ':';

        let msString = ""
        let ms = date.getMilliseconds()
        if (ms < 10) {
            msString = '00' + ms
        } else if (ms < 100) {
            msString = '0' + ms
        } else {
            msString = '' + ms
        }

        return "[" + Y + M + D + h + m + s + msString + "]";
    }

    public static formatTimeInMsToFileName(timeMs: number) {
        let date = new Date(timeMs);//如果date为13位不需要乘1000
        let Y = date.getFullYear() + '_';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '_';
        let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + '_';
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + '_';
        let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()) + '_';

        let msString = ""
        let ms = date.getMilliseconds()
        if (ms < 10) {
            msString = '00' + ms
        } else if (ms < 100) {
            msString = '0' + ms
        } else {
            msString = '' + ms
        }

        return Y + M + D + h + m + s + msString;
    }

    public static formatTimeInMinute(timeMs: number) {
        let date = new Date(timeMs);//如果date为13位不需要乘1000
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());

        return Y + M + D + h + m;
    }

    // 时-分-秒
    public static formatTimeInSecond(timeMs: number) {
        let date = new Date(timeMs);//如果date为13位不需要乘1000

        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());

        return "[" + h + m + s + "]";
    }

    // 时-分
    public static formatTimeInSecond3(timeMs: number) {
        let date = new Date(timeMs);//如果date为13位不需要乘1000

        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());

        return "[" + h + m  + "]";
    }

    // 日-时-分-秒
    public static formatTimeInSecond2(timeMs: number) {
        let date = new Date(timeMs);//如果date为13位不需要乘1000

        let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());

        return "[" + D + h + m + s + "]";
    }

    // 年-月-日
    public static formatTimeInDay(timeMs: number) {
        let date = new Date(timeMs);//如果date为13位不需要乘1000
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
        return Y + M + D;
    }

    // 月-日
    public static formatTimeInDay2(timeMs: number) {
        let date = new Date(timeMs);//如果date为13位不需要乘1000
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
        return M + D;
    }

    public static formatDuration(timeMs: number): string {
        if (timeMs < 0) timeMs = -timeMs;

        const time = {
            天: Math.floor(timeMs / 86400000),
            小时: Math.floor(timeMs / 3600000) % 24,
            分: Math.floor(timeMs / 60000) % 60,
            秒: Math.floor(timeMs / 1000) % 60,
            //   毫秒: Math.floor(timeMs) % 1000
        };

        return Object.entries(time)
            .filter(val => val[1] !== 0)
            .map(([key, val]) => `${val}${key}`)
            .join('');
    };

    public static formatDuration2(timeMs: number): string {
        if (timeMs < 0) timeMs = -timeMs;

        const time = {
            d: Math.floor(timeMs / 86400000),
            h: Math.floor(timeMs / 3600000) % 24,
            m: Math.floor(timeMs / 60000) % 60,
            s: Math.floor(timeMs / 1000) % 60,
        };

        if (time.d > 0) {
            time.m = 0
            time.s = 0
        }
        if (time.h > 0) {
            time.s = 0
        }

        return Object.entries(time)
            .filter(val => val[1] !== 0)
            .map(([key, val]) => `${val}${key}`)
            .join('');
    };

    public static formatDuration3(timeMs: number): string {
        if (timeMs < 0) timeMs = -timeMs;

        const time = {
            时: Math.floor(timeMs / 3600000) % 24,
            分: Math.floor(timeMs / 60000) % 60,
        };

        return Object.entries(time)
            .filter(val => val[1] !== 0)
            .map(([key, val]) => `${val}${key}`)
            .join('');
    };
}

export default Format

