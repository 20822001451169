import { StoreModel } from "../model/model";
import Format from "./Format";

class Utils {
    public static isStringEmpty(obj: any): boolean {
        if (typeof obj === "undefined" || obj === null || obj === "") {
            return true;
        } else {
            return false;
        }
    }

    public static exportContentToClipboard(content: string) {
        let transfer = document.createElement('textarea');
        // 不这么设置屏幕会跳，具体哪儿有问题还没确定
        transfer.style.cssText = "width:30%;padding:2%;min-width: 100px;opacity: 0.5;color: rgb(255, 255, 255);line-height: 18px;text-align: center;border-radius: 5px;position: fixed;top: 50%;left: 30%;z-index: 999999;background: rgb(0, 0, 0);font-size: 15px;";
        document.body.appendChild(transfer);
        transfer.value = content;  // 这里表示想要复制的内容
        transfer.focus();
        transfer.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
        }
        transfer.blur();
        document.body.removeChild(transfer);
    }

    public static downloadFile(fileName: string, content: string) {
        console.log("download")
        // 创建隐藏的可下载链接
        var eleLink = document.createElement('a');
        eleLink.download = fileName;
        eleLink.style.display = 'none';
        // 字符内容转变成blob地址
        var blob = new Blob([content]);
        eleLink.href = URL.createObjectURL(blob);
        // 触发点击
        document.body.appendChild(eleLink);
        eleLink.click();
        // 然后移除
        document.body.removeChild(eleLink);
    }

    // 业务方法
    public static downloadModelFile(storeModel: StoreModel) {
        Utils.downloadFile(`DoingList_${Format.formatTimeInMsToFileName(Utils.getTimestamp())}.dl`, JSON.stringify(storeModel))
    }

    public static generateId(): number {
        return (new Date()).getTime()
    }

    // rename currentTimpstamp
    public static getTimestamp(): number {
        return (new Date()).getTime()
    }

    public static match(src: string, regex: string): boolean {
        return src.search(new RegExp(regex, 'i')) > -1
    }

    // todo 不能重载吗？
    public static matchs(src: string, regExp: RegExp): boolean {
        return src.search(regExp) > -1
    }

    public static getRandomColor(): string {
        return '#' + (Math.random() * 0xffffff << 0).toString(16);
    }

    public static hasIntersection(start1: number, end1: number, start2: number, end2: number): boolean {

        if (end2 < start1 || end1 < start2) {
            return false
        } else {
            return true
        }
    }

    public static removeObject(array: any[], object: any): boolean {
        let length = this.length;
        for (let i = 0; i < length; i++) {
            if (array[i] === object) {
                if (i === 0) {
                    array.shift(); //删除并返回数组的第一个元素
                } else if (i === length - 1) {
                    array.pop();  //删除并返回数组的最后一个元素
                } else {
                    array.splice(i, 1); //删除下标为i的元素
                }
                return true
            }
        }

        return false
    }
}

export default Utils