import 'reflect-metadata'
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import TaskListContainer from './TaskListContainer'
import ContentLayout from './ContentLayout'
import TimeLineLayout from './TimeLineLayout'
import TodayWorkConatiner from './TodayWorkConatiner';
import stores from '../stores';

const AppContainer = styled.div`
  display: grid;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  grid-template-columns: 35% 45% 20%;
  grid-template-rows: 100%;
`

const App: React.FC = () => {
  // useEffect(() => {
  //   console.log("app useEffect")
  // },[])

  // todo 暂时只会这么用
  const { fetchData } = stores.useStore('mainmodel') as { fetchData: () => void }
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <AppContainer>
      <TaskListContainer />
      <ContentLayout />

      <div>
        <TimeLineLayout />
        <TodayWorkConatiner />
      </div>

    </AppContainer>
  );
}

/**
 * 这个还是不work
 */
function useInterval(callback:any, delay:number) {
  const savedCallback = useRef<any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}


export default App;
