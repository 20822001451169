import { Task } from '../model/model'

const CurrentTaskKey = "CurrentTaskKey"

const currentTaskStore = {
    currentTask: undefined as Task|undefined,

    setCurrentTask(task: Task|undefined) {
        this.currentTask = task
    },
};

export {CurrentTaskKey, currentTaskStore}