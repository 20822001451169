import { Task } from '../model/model'
import Format from '../utils/Format'
import Utils from '../utils/Utils'

class TaskInfoFormat {

    public static getDoingStatusTips(task: Task): string {
        if (task.isDoing()) {
            return "Doing 🔴"
            // ⛑🏃‍♀️🧠 🏄‍♂️ 🌟 🔴
        } else {
            return ""
        }
    }

    public static getConsumed(task: Task): string {
        let time = Format.formatDuration2(task.getAllDuration());
        if (Utils.isStringEmpty(time)) {
            return ""
        }
        return `进行${time}(${task.doingDurs.length}次) `
    }

    public static getDeadline(task: Task): string {
        const pendingStatus = TaskInfoFormat.getPendingStatus(task)
        if (Utils.isStringEmpty(pendingStatus)) {
            return TaskInfoFormat.getDeadlineStatus(task)
        } else {
            return pendingStatus
        }
    }

    private static getDeadlineStatus(task: Task): string {
        let deadline = task.getActualDeadline()
        if (deadline <= 0) {
            return ""
        }
        const leftTime = deadline - Utils.getTimestamp();
        if (leftTime <= 1000) {
            return `Dead超期❗ `
        }
        let time = Format.formatDuration2(leftTime);
        return `Dead剩余${time} `
    }

    // todo 合并重复函数
    private static getPendingStatus(task: Task): string {
        let deadline = task.pendingDeadline
        if (deadline <= 0) {
            return ""
        }
        const leftTime = deadline - Utils.getTimestamp();
        if (leftTime <= 1000) {
            return `Pend超期(${task.pendingReason})❗`
        }
        let time = Format.formatDuration2(leftTime);
        return `Pend剩余${time} `
    }

    public static getConsumeExpected(task: Task): string {
        if (task.getActualExpectConsume() <= 0) {
            return ""
        }

        let left = task.getLeftExpectConsumeTime()
        if (left <= 0) {
            return `多用${Format.formatDuration2(-left)} `
        } else {
            return `还要${Format.formatDuration2(left)} `
        }
    }

    public static getStartExpected(task: Task): string {
        const currentTime = Utils.getTimestamp()
        if (currentTime > task.expectStartTime) {
            return ""
        }

        return `还有${Format.formatDuration2(task.expectStartTime - currentTime)}开始`
    }
}

export default TaskInfoFormat