import 'reflect-metadata'
import React, { useState } from 'react';
import styled from 'styled-components';
import stores from '../stores';
import { List } from 'antd';
import { StoreModel, Task, TimeLine } from '../model/model'
import DateUtils from '../utils/DateUtils'
import Format from '../utils/Format'
import { CurrentTaskKey } from '../stores/CurrentTaskStore';
import { CurrentDayOffsetKey } from '../stores/CurrentDayOffsetStore';



const Div = styled.div`
height: 45vh;
overflow-y: scroll;
white-space: pre-wrap;
background-color:#ffffff;

::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .5);
            box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
`

const NoteItem = styled.div`
padding:2px;
margin:1px;
:hover{
  background-color: ${(props: { choosed: boolean }) => {
    if (props.choosed) {
      return '#f5b52b'
    } else {
      return '#b4e7fc'
    }
  }
  }
};
background-color: ${(props: { choosed: boolean }) => {
    if (props.choosed) {
      return '#f5b52b'
    } else {
      return 'rgba(194, 226, 255, 0.495)'
    }
  }
  };

`

const TodayWorkConatiner: React.FC = () => {
  const { storeModel } = stores.useStore('mainmodel') as { storeModel: StoreModel | undefined };
  const { currentTask, setCurrentTask } = stores.useStore(CurrentTaskKey) as { currentTask: Task | undefined, setCurrentTask: (note: Task) => void }
  const { currentDayOffset, setCurrentDayOffset } = stores.useStore(CurrentDayOffsetKey) as { currentDayOffset: number, setCurrentDayOffset: (offset: number) => void }

  function getCurrentDayDoingTasks(): Task[] {
    if (storeModel === undefined) {
      return []
    }
    return storeModel.getCurrentDayDoingTasks(DateUtils.getMyCurrentDayDur(currentDayOffset))
      .sort((a, b) => {
        if (a.lastStartTime() > b.lastStartTime()) {
          return -1
        } else if (a.lastStartTime() < b.lastStartTime()) {
          return 1
        } else {
          return 0
        }
      }
      )
  }

  return (
    <Div>
      <List
        size="small"
        bordered
        dataSource={getCurrentDayDoingTasks()}
        renderItem={task => (
          <NoteItem choosed={task.isSameTask(currentTask)} key={task.id} onClick={(e) => setCurrentTask(task)}>
            {task.title}
            <span style={{ color: "#0295d2" }}>{Format.formatDuration3(task.getAllDurationInDur(DateUtils.getMyCurrentDayDur(currentDayOffset)))}</span>
          </NoteItem>
        )}
      />
    </Div>
  );
}

export default TodayWorkConatiner;
