
const CurrentDayOffsetKey = "CurrentDayOffsetKey"

const currentDayOffsetStore = {
    currentDayOffset: 0,

    setCurrentDayOffset(offset: number) {
        this.currentDayOffset = offset
    },
};

export {CurrentDayOffsetKey, currentDayOffsetStore}