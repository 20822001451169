import Dexie, { PromiseExtended } from 'dexie';
import { Task, Duration, StoreModel, AdjustConsume, Note, CycleRecord, TimeLine, ChildTask } from '../model/model'
import Utils from '../utils/Utils';
import { plainToClass } from "class-transformer";

interface ITaskDBItem {
    dbId?: number
    id: number
    modifyTime: number
    jsonContent: string
}

interface CycleRecordDBItem {
    dbId?: number
    id: number
    modifyTime: number
    jsonContent: string
}

class MainDB extends Dexie {
    public tasks: Dexie.Table<ITaskDBItem, number>;
    public cycleRecords: Dexie.Table<CycleRecordDBItem, number>;

    public constructor() {
        super("MainDB_V1");

        // 这玩意的升级好像有点问题
        this.version(1).stores({
            tasks: "++dbId, id, modifyTime, jsonContent",
            cycleRecords: "++dbId, id, modifyTime, jsonContent"
        });
        this.tasks = this.table("tasks");
        this.cycleRecords = this.table("cycleRecords");
    }

    public deleteAll(){
        this.transaction('rw', this.tasks, this.cycleRecords, async () => {
            this.tasks.clear()
            this.cycleRecords.clear()
        }).catch(e => {
            alert(e.stack || e);
        });
    }

    /* ----------------------------------------------------------------------------------- */

    public saveTask(task: Task) {
        this.transaction('rw', this.tasks, async () => {
            // task.dbId = await this.tasks.put(new TaskDBItem(task.id, Utils.getTimestamp(), JSON.stringify(task), task.dbId))

            console.log(`SaveTask dbId:${task.dbId} id:${task.id} name:${task.title}`)

            if (task.dbId) {
                task.dbId = await this.tasks.put({ dbId: task.dbId, id: task.id, modifyTime: Utils.getTimestamp(), jsonContent: JSON.stringify(task) })
            } else {
                task.dbId = await this.tasks.put({ id: task.id, modifyTime: Utils.getTimestamp(), jsonContent: JSON.stringify(task) })
            }
        }).catch(e => {
            alert(e.stack || e);
        });
    }

    /**
     * todo 先取所有的，之后可以优化成需要什么取什么
     * 这里不用promise是因为其实是一样的，还不如封装地简单一点
     */
    public fetchAllTasks(callback: (tags: Task[]) => void) {
        this.transaction('rw', this.tasks, async () => {
            const dbTags = await this.tasks.toArray();
            callback(dbTags.map(value => {
                const task = plainToClass(Task, JSON.parse(value.jsonContent))
                task.dbId = value.dbId
                task.adaptData()
                return task
            }).filter(task => !task.isDeleted))
        }).catch(e => {
            alert(e.stack || e);
        });
    }

    /* ----------------------------------------------------------------------------------- */
    /**
     * 完全是一模一样的模板...
     */
    public saveCycleRecord(task: CycleRecord) {
        this.transaction('rw', this.cycleRecords, async () => {
            task.dbId = await this.cycleRecords.put({ id: task.id, modifyTime: Utils.getTimestamp(), jsonContent: JSON.stringify(task) })
        }).catch(e => {
            alert(e.stack || e);
        });
    }

    public fetchAllCycleRecord(callback: (tags: CycleRecord[]) => void) {
        this.transaction('rw', this.cycleRecords, async () => {
            const dbTags = await this.cycleRecords.toArray();
            callback(dbTags.map(value => {
                const task = plainToClass(CycleRecord, JSON.parse(value.jsonContent))
                task.dbId = value.dbId
                return task
            }))
        }).catch(e => {
            alert(e.stack || e);
        });
    }
}

const mainDB = new MainDB()

export default mainDB