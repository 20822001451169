import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input, message, Switch, Button, List, Row, Col, Dropdown, Menu, Modal, DatePicker } from 'antd';
import stores from '../stores';
import { Task } from '../model/model'
import { StoreModel, Duration, TimeLine, AdjustConsume, ChildTask } from '../model/model'
import Utils from '../utils/Utils'
import DateUtils from '../utils/DateUtils'
import MomentUtils from '../utils/MomentUtils'
import TaskInfoFormat from '../utils/TaskInfoFormat'
import Format from '../utils/Format'
import { CurrentTaskKey } from '../stores/CurrentTaskStore';
import 'antd/dist/antd.css';
import { CurrentDoingTaskKey } from '../stores/CurrentDoingTaskStore';
import { CurrentDayOffsetKey } from '../stores/CurrentDayOffsetStore';
import { ClickParam } from 'antd/lib/menu';
// import { DateType } from 'antd/lib/date-picker';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment'
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

const Container = styled.div`
  background: #ffffff;
  display: flex;
  height: 100vh;
  flex-direction: column;
`

const Div = styled.div`
`

// const Editor = styled(Input.TextArea)`
//   flex-grow: 1;
// `
const Editor = styled(SimpleMDE)`
`
const EditorContainer = styled.div`
  flex-grow: 1;
`

const ButtonGroup = styled(Button.Group)`
margin: 2px;
`

const TimeLinesContainer = styled.div`
  height: 250px;
overflow-y: scroll;
margin-left:5px;
/* margin-top:1px; */
margin-bottom:5px;
white-space: pre-wrap;
background-color:#ffffff;
/* background-color:rgb(227, 233, 231); */

::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .5);
            box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
`


const ChildTaskItem = styled.div`
padding-top:1px;
padding-bottom:1px;
padding-left:2px;
padding-right:2px;
:hover{
background-color: #ffcdc5;
};
background-color:#fdf0ee;
font-size:14px;

opacity : ${(props: { isDone: boolean }) => {
    if (props.isDone) {
      return 0.15
    } else {
      return 1
    }
  }
  };
`

const NoteItem = styled.div`
    padding-top:1px;
    padding-bottom:1px;
    padding-left:2px;
    padding-right:2px;
    :hover{
  background-color: #a2e3cd;
  };
  background-color:#e3e9e7;
    font-size:12px;
  `

const DeleteBtn = styled.span`
  padding-left:4px;
  padding-right:4px;
  margin-left:2px;
  margin-right:2px;
  color:#fff;
  :hover{
    background-color: #02472f;
    opacity:1;
    };
    background-color:#00b477;
    opacity : ${(props: { isHover: boolean }) => {
    if (props.isHover) {
      return 1
    } else {
      return 0.05
    }
  }
  };
`

const ChildTaskBtn = styled(DeleteBtn)`
  padding-left:2px;
  padding-right:2px;
  margin-left:1px;
  margin-right:1px;
  :hover{
    background-color: #901401;
    opacity:1;
    };
    background-color:#fd7560;
    opacity : ${(props: { isHover: boolean }) => {
    if (props.isHover) {
      return 1
    } else {
      return 0.05
    }
  }
  };
`

const ContentLayout: React.FC = () => {
  const { storeModel, saveTask, saveConfig } = stores.useStore('mainmodel') as { storeModel: StoreModel | undefined, saveTask: (task: Task) => void, saveConfig: () => void }

  const { currentTask } = stores.useStore(CurrentTaskKey) as { currentTask: Task | undefined }
  const { currentDayOffset, setCurrentDayOffset } = stores.useStore(CurrentDayOffsetKey) as { currentDayOffset: number, setCurrentDayOffset: (offset: number) => void }

  const [content, setContent] = useState("")
  const [timeline, setTimeline] = useState("")
  const [childTaskInput, setChildTaskInput] = useState("")

  const [isExpectConsume, setExpectConsumeUI] = useState(false);
  const [expectTime, setExpectTime] = useState(-1);

  const [newAdjustTime, setNewAdjustTime] = useState("");

  const [isHaveDeadline, setHaveDeadlineUI] = useState(false);
  const [deadline, setDeadlineUI] = useState<any>(null);

  const [isPending, setPendingUI] = useState(false);
  const [pendingReason, setPendingReason] = useState("");
  const [pendingTime, setPendingTimeUI] = useState<any>(null);

  const [hoverTimeline, setHoverTimeline] = useState<TimeLine | null>(null);
  const [hoverTask, setHoverTask] = useState<ChildTask | null>(null);

  const [expectStartTime, setExpectStartTimeUI] = useState<any>(null);


  // toolsbar
  const { currentDoingTask, setCurrentDoingTask } = stores.useStore(CurrentDoingTaskKey) as { currentDoingTask: Task | undefined, setCurrentDoingTask: (note: Task | undefined) => void }

  /* ---------------------------初始化------------------------------- */

  // 渲染当前note
  const [lastTask, setLastTask] = useState<Task | undefined>(undefined)
  useEffect(() => {
    // console.log(`Current content:${content}`)
    if (currentTask === undefined) return

    if (!currentTask.isSameTask(lastTask)) {
      // todo 这里放同步应该不会有问题吧，最大的问题应该是前面切了note但没切content的时候进行保存...
      setLastTask(currentTask)
      setContent(currentTask.note.content)

      // todo 这部分逻辑抽出去
      const expectTime = currentTask.getActualExpectConsume()
      if (expectTime < 0) {
        setExpectTime(expectTime)
        setExpectConsume(false, true)
      } else {
        setExpectConsume(true, true)
        setExpectTime(expectTime / (3600 * 1000))
      }

      setNewAdjustTime("")

      setPendingUI(currentTask.isPending())
      setPendingReason(currentTask.pendingReason)
      setPendingTimeUI(getPendingDeadlineTime(currentTask))

      setDeadlineUI(getDeadlineTime(currentTask))
      setHaveDeadline(currentTask.isHaveDeadline(), true)

      if (currentTask !== undefined && !currentTask.isStarted()) {
        setExpectStartTimeUI(moment(currentTask.expectStartTime))
      } else {
        setExpectStartTimeUI(null)
      }

      // todo 优化，别在这刷新1
      storeModel?.normalUpdate(DateUtils.getMyCurrentDayDur(currentDayOffset))
    }
  })

  /* ----------------------------保存Note文本------------------------------ */

  function onBlur(event: any) {
    event.preventDefault();
    saveToLocal(event, false)
  }

  function saveToLocal(event: any, isManual: boolean) {
    if (event) event.preventDefault();
    if (currentTask === undefined) return

    currentTask.note.content = content
    saveTask(currentTask)

    if (isManual) message.info("已保存")
  }

  /* ------------------------------toolsbar---------------------------- */

  function deleteTask(event: any) {
    if (currentTask === undefined) return

    Modal.confirm({
      title: '确定要删除吗？',
      content: `是否删除任务：${currentTask.title}`,
      onOk() {
        currentTask.isDeleted = true
        saveTask(currentTask)
        message.success("删除任务成功")
      },
    });
  }

  function doneTask(event: any) {
    if (currentTask === undefined) return
    const task = currentTask

    if (task.isDone()) {
      message.error(`任务已完成，完成时间:${Format.formatTimeInMs(task.doneTime)}`)
      return
    }

    const undoneChildTask = task.childTasks.find((task) => !(task.isDone() || task.isDelay))
    if (undoneChildTask !== undefined) {
      message.error(`有子任务未完成:${undoneChildTask.name}`)
      return
    }

    if (!task.isDoing()) {
      Modal.confirm({
        title: '当前任务不在进行中，确定要完成吗？',
        content: `是否完成任务：${task.title}`,
        onOk() {
          _realDone(task)
        },
      });
      return
    }

    task.stop()
    setCurrentDoingTask(undefined)
    _realDone(task)
  }

  function _realDone(task: Task) {
    task.done()
    storeModel?.normalUpdate(DateUtils.getMyCurrentDayDur(currentDayOffset))
    message.success("任务已完成")
    saveTask(task)
  }

  function onChooseDeadline(value: any) {
    if (currentTask === undefined) return
    const task = currentTask

    // 整了半天，原来直接 valueOf() 就行，百度了半天
    // if(value instanceof Moment){
    //   let moment:Moment = value
    //   console.log('onOk: ', moment.);
    // }
    // console.log('onOk: ', value.valueOf());

    task.deadlines.push(value.valueOf())
    setDeadlineUI(getDeadlineTime(currentTask))
    saveTask(task)
  }

  function getDeadlineTime(task: Task): any {
    const deadline = task.getActualDeadline();
    if (deadline > 0) {
      return moment(deadline)
    }

    return null
  }

  function getShowTips(): string {
    if (currentTask === undefined) return ""
    const task = currentTask

    let result = TaskInfoFormat.getDoingStatusTips(task) + TaskInfoFormat.getConsumed(task) + TaskInfoFormat.getDeadline(task) + TaskInfoFormat.getConsumeExpected(task) + TaskInfoFormat.getStartExpected(task)
    return result
  }

  function setAdjustTime() {
    if (storeModel === undefined) {
      message.error("storeModel 未初始化")
      return
    }
    if (currentTask === undefined) return
    const task = currentTask

    const newAdjustTimeHours = Number(newAdjustTime)
    if (newAdjustTimeHours === 0 || newAdjustTimeHours === NaN) {
      message.error(`请输入合法数字`)
      return
    }

    const newAdjustTimeMills = newAdjustTimeHours * 3600 * 1000

    if (newAdjustTimeMills + task.getAllDuration() < 0) {
      message.error(`调整时间超过已消耗时间`)
      return
    }

    task.adjusts.push(AdjustConsume.create(newAdjustTimeMills))
    storeModel.config.totalAdjustConsumeTime += newAdjustTimeMills
    saveConfig()

    let msg = ""
    if (newAdjustTimeHours > 0) {
      msg = `增加 ${newAdjustTimeHours} `
    } else {
      msg = `减少 ${-newAdjustTimeHours} `
    }
    message.success(`耗时已${msg}小时`)

    setNewAdjustTime("")

    saveTask(task)
  }

  function changeExceptTime() {
    if (currentTask === undefined) return
    const task = currentTask

    if (expectTime == -1) {
      task.expectConsumes.push(-1) // todo 暂时不优化交互了，-1就是不设置时间
    } else {
      task.expectConsumes.push(expectTime * 3600 * 1000)
    }

    saveTask(task)
  }

  /* ---------------------------------------------------------- */

  function onChoosePendingDeadline(value: any) {
    if (currentTask === undefined) return
    const task = currentTask

    if (!isPending) {
      message.error(`设置无效，请打开 pending 开关后重新设置`)
      return
    }

    const pendingLine = value.valueOf()
    if (!isTimeValid(pendingLine, "Pending时间", Duration.create(undefined, task.getActualDeadline(), "Deadline"))) {
      return
    }

    task.pendingDeadline = pendingLine
    setPendingTimeUI(getPendingDeadlineTime(task))
    saveTask(task)
  }

  function onChangeExpectStartTime(value: any, task: Task) {
    const expectStartTime = value.valueOf()

    if (!isTimeValid(expectStartTime, "预期开始时间", Duration.create(undefined, task.pendingDeadline, "PendLine"), Duration.create(undefined, task.getActualDeadline(), "Deadline"))) {
      return
    }

    setExpectStartTimeUI(moment(expectStartTime))
    task.expectStartTime = expectStartTime
    saveTask(task)
  }

  function isTimeValid(time: number, timeName: string, ...limitDurations: Duration[]): boolean {
    let isValid = true
    for (let index = 0; index < limitDurations.length; index++) {
      const dur = limitDurations[index];
      if (dur.start > 0 && time < dur.start) {
        message.error(`${timeName}(${Format.formatTimeInMinute(time)})早于${dur.start}(${Format.formatTimeInMinute(dur.start)})，请重新设置`)
        isValid = false
      } else if (dur.end > 0 && time > dur.end) {
        message.error(`${timeName}(${Format.formatTimeInMinute(time)})晚于${dur.end}(${Format.formatTimeInMinute(dur.end)})，请重新设置`)
        isValid = false
      }
      if (!isValid) {
        return false
      }
    }
    return true
  }

  function getPendingDeadlineTime(task: Task): any {
    const deadline = task.pendingDeadline

    if (deadline > 0) {
      return moment(deadline)
    }

    return null
  }

  function savePendingStatus() {
    if (currentTask === undefined) return
    const task = currentTask

    task.pendingReason = pendingReason
    saveTask(task)
  }

  function setPending(pendingStatus: boolean) {
    if (currentTask === undefined) return
    const task = currentTask

    if (!pendingStatus) {
      task.pendingDeadline = -1
      setPendingTimeUI(null)
      saveTask(task)
    }
    setPendingUI(pendingStatus)
  }

  function setExpectConsume(pendingStatus: boolean, isOnlyRender:boolean) {
    if (currentTask === undefined) return
    const task = currentTask

    // 关闭就不估时并保存
    if (!pendingStatus) {
      if(!isOnlyRender){
        task.expectConsumes.push(-1)
        saveTask(task)
      }
      setExpectTime(-1)
    }
    setExpectConsumeUI(pendingStatus)
  }

  function setHaveDeadline(pendingStatus: boolean, isOnlyRender: boolean) {
    if (currentTask === undefined) return
    const task = currentTask

    // 关闭就不估时并保存
    if (!pendingStatus) {
      if(!isOnlyRender){
        task.deadlines.push(-1)
        saveTask(task)
      }
      setDeadlineUI(null)
    }
    setHaveDeadlineUI(pendingStatus)
  }

  /* ---------------------------------------------------------- */

  function onDeleteTimeline(task: Task, timeline: TimeLine) {
    // 区分自动生成的开始结束
    if (timeline.isStartEndAutoGen) {
      Modal.confirm({
        title: '确定要删除此 Dur 吗？',
        content: `是否删除此 Dur，这意味着消耗时间也会重新计算，将不可恢复，请仔细确认`,
        onOk() {
          if (task.removeDuration(timeline.timeStamp)) {
            message.success("删除 Dur 成功")
            saveTask(task)
          } else {
            message.error("删除 Dur 失败")
          }
        },
      });
    } else if (timeline.isChildTaskAutoGen) {
      message.error("无法删除，直接操作子任务即可")
    } else {
      Modal.confirm({
        title: '确定要删除此 TimeLine 吗？',
        content: `是否删除 ${timeline.content}，将不可恢复`,
        onOk() {
          if (task.removeTimeline(timeline)) {
            message.success("删除TimeLine成功")
            saveTask(task)
          } else {
            message.error("删除TimeLine失败")
          }
        },
      });
    }
  }

  function onAddTimeline(event: any) {
    event.preventDefault();

    if (currentTask === undefined) return

    if (Utils.isStringEmpty(timeline)) {
      message.error("请输入内容")
      return
    }

    // const endTimeline = currentTask.note.endTimeLine();
    // if (endTimeline == null || !DateUtils.isSameDay(endTimeline.timeStamp, Utils.getTimestamp())) {
    // currentTask.note.timeLines.push(TimeLine.createDivNode())
    // }

    currentTask.note.timeLines.push(TimeLine.createContentNode(timeline))

    saveTask(currentTask)

    setTimeline("")
  }

  function onAddChildTask(task: Task) {
    if (Utils.isStringEmpty(childTaskInput)) {
      message.error("请输入内容")
      return
    }

    task.childTasks.push(ChildTask.create(childTaskInput))

    saveTask(task)

    setChildTaskInput("")
  }


  /* ---------------------------------------------------------- */

  function onDeleteChildTask(task: Task, childTask: ChildTask) {
    Modal.confirm({
      title: '确定要删除此 ChildTask 吗？',
      content: `是否删除 ChildTask: ${childTask.name}，将不可恢复，请仔细确认`,
      onOk() {
        if (task.removeChildTask(childTask)) {
          message.success("删除 ChildTask 成功")
          saveTask(task)
        } else {
          message.error("删除 ChildTask 失败")
        }
      },
    });
  }

  function onDoneChildTask(task: Task, childTask: ChildTask) {
    if (childTask.isDone()) {
      childTask.reStart()
      message.success("任务已重新开始")
    } else {
      childTask.done()
      message.success("任务已完成")
    }
    saveTask(task)
  }

  function onDelayChildTask(task: Task, childTask: ChildTask) {
    childTask.isDelay = !childTask.isDelay
    saveTask(task)
  }

  function onPendChildTask(task: Task, childTask: ChildTask) {
    setPendingReason(childTask.name)
    task.pendingReason = childTask.name
    saveTask(task)
  }

  function onOrderChildTask(task: Task, childTask: ChildTask, isAdd: boolean) {
    if (isAdd) {
      childTask.addOrder()
    } else {
      childTask.downOrder()
    }
    saveTask(task)
  }

  /* ---------------------------------------------------------- */

  function getOutTimeCyclerTaskTips(task: Task): string {
    if (!task.isCycleTask()) return ""

    if (DateUtils.getMyCurrentDayDur(0).isIn(task.expectStartTime)) {
      return ""
    }

    return "【超期❌】"
  }

  /* ---------------------------------------------------------- */

  const editorRef = React.useRef(null)

  // 修改编辑器高度
  // todo sizechange的时候要刷新
  useEffect(() => {

    const $codeMirror = document.querySelector('.CodeMirror');
    const editorDom = editorRef.current as HTMLElement | null

    if ($codeMirror && editorDom) {
      $codeMirror.setAttribute('style', 'height:' + (editorDom.clientHeight - 88) + 'px;box-shadow:none');
    }
  })

  /* ---------------------------------------------------------- */

  function getItem(timeline: TimeLine, currentTask: Task): JSX.Element {
    if (timeline.isDivder) {
      return <span style={{ backgroundColor: "#f9e6ba", color: "#ce9100" }}>{`┗━ ${Format.formatTimeInDay(timeline.timeStamp)} ━━━━`}</span>
    } else if (timeline.isChildTaskAutoGen) {
      return  <span>
      <span style={{ color: "#ce9100", paddingRight: "2px" }}> {Format.formatTimeInSecond(timeline.timeStamp)}</span>
      <span style={{ color: "#333333", fontWeight: "bold"}}>  {timeline.content} </span>

      <DeleteBtn isHover={hoverTimeline?.timeStamp === timeline.timeStamp} onClick={(e) => onDeleteTimeline(currentTask, timeline)}>删除</DeleteBtn>
      </span>
    } else {
      return <span>
      <span style={{ color: "#ce9100", paddingRight: "2px" }}> {Format.formatTimeInSecond(timeline.timeStamp)}</span>
      {timeline.content}
      {/* todo 可以做成悬浮在最后的按钮，然后hover的时候显示 */}
      <DeleteBtn isHover={hoverTimeline?.timeStamp === timeline.timeStamp} onClick={(e) => onDeleteTimeline(currentTask, timeline)}>删除</DeleteBtn>
    </span>
    }
  }

  /* ---------------------------------------------------------- */

  if (currentTask === undefined) return (<Div></Div>)

  return (
    <Container >
      {/* 不加这个 div 会换行 */}
      {currentTask !== undefined &&
        <Div style={{ margin: "5px" }}>

          <div style={{ marginLeft: "5px", marginRight: "5px", fontWeight: "bold" }}>{currentTask.title} {getOutTimeCyclerTaskTips(currentTask)}</div>

          <ButtonGroup>
            <Button onClick={(e) => deleteTask(e)}>D</Button>
            <Button onClick={(e) => doneTask(e)}>Done</Button>
          </ButtonGroup>

          <Switch style={{ marginLeft: '5px', marginRight: '3px' }} checkedChildren="估时" unCheckedChildren="不限" checked={isExpectConsume} onChange={(event) => setExpectConsume(event, false)} />

          <Switch style={{ marginLeft: '5px', marginRight: '3px' }} checkedChildren="截止时间" unCheckedChildren="没有截止" checked={isHaveDeadline} onChange={(event) => setHaveDeadline(event, false)} />

          <Switch style={{ marginLeft: '9px', marginRight: '5px' }} checkedChildren="Pending" unCheckedChildren="NoPend" checked={isPending} onChange={(event) => setPending(event)} />

        </Div>
      }

      <div style={{ marginLeft: "15px", marginRight: "15px", fontWeight: "bold" }} >{getShowTips()}</div>


      {currentTask !== undefined &&
        <Div style={{ marginTop: "5px", marginBottom: "5px" }}>
          {expectStartTime !== null &&
            <span style={{ marginLeft: "15px" }} >预期开始时间：
          <DatePicker showTime value={expectStartTime} onOk={value => onChangeExpectStartTime(value, currentTask)} />
            </span>
          }

          {currentTask.isStarted() &&
            <Input style={{ marginLeft: "15px", width: '150px' }} value={newAdjustTime} placeholder={`${currentTask.allAdjusts() / (3600 * 1000)} 回车保存`} prefix="调整耗时" suffix="h" onChange={(event) => setNewAdjustTime(event.target.value)} onPressEnter={setAdjustTime} />
          }

          {isExpectConsume &&
            <span style={{ marginLeft: "15px" }} >估时：
        <Input style={{ width: '100px' }} value={expectTime} placeholder="花费时间 自动保存" prefix="预计" suffix="h" onChange={(event) => setExpectTime(Number(event.target.value))} onBlur={changeExceptTime} />
            </span>
          }


          {isHaveDeadline &&
            <span style={{ marginLeft: "15px" }} >DeadLine：
        <DatePicker showTime value={deadline} onOk={value => onChooseDeadline(value)} />
            </span>
          }

          {isPending &&
            <span style={{ marginLeft: "15px" }} >Pending：
      <DatePicker showTime value={pendingTime} onOk={value => onChoosePendingDeadline(value)} />

              <Input style={{ width: '200px' }} value={pendingReason} prefix="原因:" placeholder="（自动保存）" onChange={(event) => setPendingReason(event.target.value)} onBlur={savePendingStatus} />
            </span>
          }

        </Div>
      }

      {currentTask !== undefined &&
        <div style={{ display: "flex" }}>
          <Div style={{ marginLeft: "15px", marginRight: "5px", width: "60%" }} >
            <Input placeholder="输入时间线，回车添加" value={timeline} onChange={(event) => setTimeline(event.target.value)} onPressEnter={onAddTimeline} />

            <TimeLinesContainer>
              <List
                size="small"
                bordered
                dataSource={currentTask.note.getFormartTimeLines(currentTask.doingDurs, currentTask.childTasks)}
                renderItem={timeline => (
                  <NoteItem key={timeline.getKey()}>

                    {/* <NoteItem key={timeline.getKey()} onMouseEnter={(event) => setHoverTimeline(timeline)} onMouseLeave={(event) => setHoverTimeline(null)}> */}
                    {
                      getItem(timeline, currentTask)
                    }
                  </NoteItem>
                )}
              />
            </TimeLinesContainer>
          </Div>

          <Div style={{ marginLeft: "5px", marginRight: "15px", width: "40%" }} >
            <Input placeholder="输入子任务，回车添加" value={childTaskInput} onChange={(event) => setChildTaskInput(event.target.value)} onPressEnter={(event) => onAddChildTask(currentTask)} />

            <TimeLinesContainer>
              <List
                size="small"
                bordered
                dataSource={currentTask.getOrderedChildTasks()}
                renderItem={childTask => (
                  <ChildTaskItem key={childTask.createTime} isDone={childTask.isDone() || childTask.isDelay} >

                    {/* <ChildTaskItem key={childTask.createTime} isDone={childTask.isDone()} onMouseEnter={(event) => setHoverTask(childTask)} onMouseLeave={(event) => setHoverTask(null)}> */}
                    {/* <span style={{ color: "#ce9100", paddingRight: "2px" }}> {Format.formatTimeInSecond(timeline.timeStamp)}</span> */}

                <span style={{ fontWeight: "bold" }}>{childTask.isDone()? "【done】":""}{childTask.isDelay? "【delay】":""}{childTask.name}</span>
                    <span style={{ color: "#00000055" }}> ({childTask.order}) </span>
                    {/* todo 可以做成悬浮在最后的按钮，然后hover的时候显示 */}

                    <ChildTaskBtn isHover={hoverTask?.createTime === childTask.createTime} onClick={(e) => onDoneChildTask(currentTask, childTask)}>完成</ChildTaskBtn>
                    <ChildTaskBtn isHover={hoverTask?.createTime === childTask.createTime} onClick={(e) => onPendChildTask(currentTask, childTask)}>等待</ChildTaskBtn>
                    <ChildTaskBtn isHover={hoverTask?.createTime === childTask.createTime} onClick={(e) => onOrderChildTask(currentTask, childTask, true)}>↑</ChildTaskBtn>
                    <ChildTaskBtn isHover={hoverTask?.createTime === childTask.createTime} onClick={(e) => onOrderChildTask(currentTask, childTask, false)}>↓</ChildTaskBtn>
                    <ChildTaskBtn isHover={hoverTask?.createTime === childTask.createTime} onClick={(e) => onDeleteChildTask(currentTask, childTask)}>删</ChildTaskBtn>
                    <ChildTaskBtn isHover={hoverTask?.createTime === childTask.createTime} onClick={(e) => onDelayChildTask(currentTask, childTask)}>推迟</ChildTaskBtn>
                  </ChildTaskItem>
                )}
              />
            </TimeLinesContainer>
          </Div>


        </div>
      }

      {/* <Editor
        placeholder="任务日志（自动保存）" value={content} onChange={(event) => setContent(event.target.value)} onBlur={(e) => onBlur(e)}
      /> */}
      <EditorContainer onBlur={onBlur} ref={editorRef}>
        <Editor
          // 使用了key，没有刷新问题了  
          key={currentTask && currentTask.id}
          value={content}
          onChange={setContent}
          options={{
            spellChecker: false,
            // toolbar: false,
            // status: false, todo 内部有bug，这么设置之后粘贴图片会报错，需要拉源码过来改下
            hideIcons: ["side-by-side", "guide"]
          }}
        />
      </EditorContainer>

    </Container>
  );
}

export default ContentLayout;