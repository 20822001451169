import Utils from '../utils/Utils'
import { Type } from "class-transformer";
import DateUtils from '../utils/DateUtils'
import Format from '../utils/Format'
import storeManager from '../stores/index';
import mainDB from "../db/MainDB";


class Task {
    // todo delete 内存id增加器（因为mock初始化太快了，都在一个毫秒内）
    private static idCounter = 0

    public id: number = -1
    dbId?: number
    public title: string = "undefine"

    @Type(() => Note)
    public note: Note = new Note()

    public level: number = 3

    public createTime: number = -1
    public doneTime: number = -1

    // 这两个其实独立存储的
    public pendingDeadline: number = -1
    public pendingReason: string = ""

    // 可修改，最后一个有效
    public deadlines: number[] = []
    // 预计花费时间，单位毫秒，可修改，最后一个有效
    public expectConsumes: number[] = []
    // 预期开始时间，默认是createTime，可以设置成不同时间，如果在 expectStartTime 前开始任务，此时间会自动变成这个开始任务的时间（显示逻辑一致）
    public expectStartTime: number = -1

    @Type(() => AdjustConsume)
    public adjusts: AdjustConsume[] = []

    @Type(() => Duration)
    public doingDurs: Duration[] = []

    @Type(() => ChildTask)
    public childTasks: ChildTask[] = []

    public cycleId: number = -1

    public isDeleted: boolean = false

    // 反序列化不能有带参数的构造器
    // public constructor(title: string) {
    //     this.title = title
    //     this.createTime = Utils.getTimestamp()

    //     this.id = Utils.generateId() + Task.idCounter
    //     Task.idCounter = Task.idCounter + 1000
    // }

    public init(title: string) {
        this.title = title
        this.createTime = Utils.getTimestamp()
        this.expectStartTime = this.createTime

        this.id = Utils.generateId() + Task.idCounter
        Task.idCounter = Task.idCounter + 1000
    }

    public adaptData() {
        if (this.expectStartTime === -1) {
            this.expectStartTime = this.createTime
        }
    }

    public isSameTask(task: Task | undefined): boolean {
        if (task === undefined) {
            return false
        } else {
            return this.id === task.id
        }
    }

    public isDoing(): boolean {
        if (this.doingDurs.length === 0) {
            return false
        } else {
            let lastTask = this.doingDurs[this.doingDurs.length - 1]
            return lastTask.end === -1
        }
    }

    public isCycleTask(): boolean {
        return this.cycleId !== -1
    }

    public isStarted(): boolean {
        return this.doingDurs.length !== 0
    }

    public lastStartTime(): number {
        if (this.doingDurs.length === 0) {
            return -1
        } else {
            let lastTask = this.doingDurs[this.doingDurs.length - 1]
            return lastTask.start
        }
    }

    public start() {
        let duration = new Duration()
        const currentTime = Utils.getTimestamp();
        duration.init(currentTime)
        this.doingDurs.push(duration)

        if (currentTime < this.expectStartTime) {
            this.expectStartTime = currentTime
        }
    }

    public stop() {
        // 避免 timeline 出现这一个先开始，下一个后结束的排序问题（同一个毫秒），这里做一个偏移
        this.doingDurs[this.doingDurs.length - 1].end = Utils.getTimestamp() - 1
    }

    public stopDelay(delay: number) {
        this.doingDurs[this.doingDurs.length - 1].end = Utils.getTimestamp() + delay
    }

    public isDone(): boolean {
        return this.doneTime !== -1
    }

    public isFutureTask(dur: Duration): boolean {
        return this.expectStartTime > dur.end
    }

    public isPending(): boolean {
        return this.pendingDeadline !== -1
    }

    public isDangerousPending(): boolean {
        return this.isTimeDangers(this.pendingDeadline)
    }

    public isDangerousDeadline(): boolean {
        return this.isTimeDangers(this.getActualDeadline())
    }

    public isDangerous(): boolean {
        return this.isDangerousPending() || this.isDangerousDeadline()
    }

    /**
     * dealine 距离还有2个小时，或者已经过了
     */
    private isTimeDangers(deadline: number): boolean {
        if (deadline <= 0) {
            return false
        }

        if (deadline - Utils.getTimestamp() <= 2 * 60 * 60 * 1000) {
            return true
        }

        return false
    }

    public done() {
        this.doneTime = Utils.getTimestamp()
    }

    // 返回毫秒数
    // todo rename getAllDoingDuration
    public getAllDuration(): number {
        if (this.doingDurs.length === 0) {
            return 0
        }

        return this.doingDurs.map((value, index) => value.getDur(index === this.doingDurs.length - 1)).reduce((a, b) => a + b) + this.allAdjusts()
    }

    public allAdjusts(): number {
        if (this.adjusts.length === 0) {
            return 0
        }
        return this.adjusts.map((adjust) => adjust.adjust).reduce((a, b) => a + b);
    }

    public getAllDurationInDur(limitDur: Duration): number {
        // 完全没开始就不算了，起码点击下开始
        if (this.doingDurs.length === 0) {
            return 0
        }

        return this.doingDurs
            .map((value, index) => value.getLimitDur(index === this.doingDurs.length - 1, limitDur))
            .reduce((a, b) => a + b)
            + this.allDurAdjusts(limitDur)
    }

    public allDurAdjusts(limitDur: Duration): number {
        const s = this.adjusts.filter((adjust) => limitDur.isIn(adjust.adjustTime))
        if (s.length === 0) {
            return 0
        }
        return s.map((adjust) => adjust.adjust).reduce((a, b) => a + b);
    }

    public getActualDeadline(): number {
        if (this.deadlines.length === 0) {
            return -1
        }

        return this.deadlines[this.deadlines.length - 1]
    }

    public isHaveDeadline(): boolean {
        return this.getActualDeadline() > 0
    }

    public getActualExpectConsume(): number {
        if (this.expectConsumes.length === 0) {
            return -1
        }

        return this.expectConsumes[this.expectConsumes.length - 1]
    }

    public isExpectConsume(): boolean {
        return this.getActualExpectConsume() > 0
    }

    // 超出预期时间返回多花的时间，负值
    // 用的时候有提前判断 getActualExpectConsume 是不是返回负值
    public getLeftExpectConsumeTime(): number {
        let left = this.getActualExpectConsume() - this.getAllDuration()

        // 不用打印
        // if (left < 0) {
        //     console.error("超出预期时间")
        // }

        return left
    }

    // todo 重复代码
    public removeTimeline(timeline: TimeLine): boolean {
        const timelines = this.note.timeLines;
        let length = timelines.length;
        for (let i = 0; i < length; i++) {
            if (timelines[i].timeStamp === timeline.timeStamp) {
                if (i === 0) {
                    timelines.shift(); //删除并返回数组的第一个元素
                } else if (i === length - 1) {
                    timelines.pop();  //删除并返回数组的最后一个元素
                } else {
                    timelines.splice(i, 1); //删除下标为i的元素
                }
                return true
            }
        }
        return false
    }

    // todo 重复代码
    public removeDuration(startOrEnd: number): boolean {
        let length = this.doingDurs.length;
        for (let i = 0; i < length; i++) {
            if (this.doingDurs[i].isValid(false) && (this.doingDurs[i].start === startOrEnd || this.doingDurs[i].end === startOrEnd)) {
                if (i === 0) {
                    this.doingDurs.shift(); //删除并返回数组的第一个元素
                } else if (i === length - 1) {
                    this.doingDurs.pop();  //删除并返回数组的最后一个元素
                } else {
                    this.doingDurs.splice(i, 1); //删除下标为i的元素
                }
                return true
            }
        }
        return false
    }

    public removeChildTask(childTask: ChildTask): boolean {
        let length = this.childTasks.length;
        for (let i = 0; i < length; i++) {
            if (this.childTasks[i].createTime == childTask.createTime) {
                if (i === 0) {
                    this.childTasks.shift(); //删除并返回数组的第一个元素
                } else if (i === length - 1) {
                    this.childTasks.pop();  //删除并返回数组的最后一个元素
                } else {
                    this.childTasks.splice(i, 1); //删除下标为i的元素
                }
                return true
            }
        }
        return false
    }

    public getOrderedChildTasks(): ChildTask[] {
        return this.childTasks.sort((a, b) => {
            // 完成的放最后
            if (a.isDone() && !b.isDone()) {
                return 1
            } else if (!a.isDone() && b.isDone()) {
                return -1
            } else {
                // 然后比较level
                if (a.order > b.order) {
                    return -1
                } else if (a.order < b.order) {
                    return 1
                } else {
                    // 同样 level 比较创建时间
                    if (a.createTime > b.createTime) {
                        return -1
                    } else if (a.createTime < b.createTime) {
                        return 1
                    } else {
                        return 0
                    }
                }
            }
        })
    }
}

class Duration {
    public start: number = -1
    public end: number = -1
    public name: string = ""

    // todo 没法重载
    public init(start: number) {
        this.start = start
    }
    public init2(start: number, end?: number) {
        this.start = start
        if (end != undefined) {
            this.end = end
        }
    }

    public isIn(timeStamp: number) {
        return timeStamp >= this.start && timeStamp <= this.end
    }

    public getDur(isDoing: boolean = false): number {
        const dur = this.getDurDur(isDoing)
        if (dur === null) {
            return 0
        }
        return dur.end - dur.start
    }

    public getDurDur(isDoing: boolean = false): Duration | null {
        let end = this.end
        if (end == -1 && isDoing) {
            end = Utils.getTimestamp()
        }
        if (end == -1) {
            console.error("这段时间未结束")
            return null
        }
        return Duration.create(this.start, end)
    }

    /**
     * 计算跟 limitDur 的交集时长
     */
    public getLimitDur(isDoing: boolean, limitDur: Duration): number {
        const dur = this.getDurDur(isDoing)?.getJoin(limitDur)
        if (dur === null || dur === undefined) {
            return 0
        }
        return dur.end - dur.start
    }

    public isValid(isDoing: boolean) {
        if (!isDoing && this.end == -1) return false
        return true
    }

    public static create(start?: number, end?: number, name?: string): Duration {
        let dur = new Duration()
        if (start != undefined) {
            dur.start = start
        }
        if (end != undefined) {
            dur.end = end
        }
        if (name != undefined) {
            dur.name = name
        }
        return dur
    }

    public getJoin(limitDur: Duration): Duration | null {
        if (this.end <= limitDur.start || this.start >= limitDur.end) {
            return null
        }

        return Duration.create(Math.max(limitDur.start, this.start), Math.min(limitDur.end, this.end))
    }
}

class AdjustConsume {
    // 调整耗时 单位毫秒
    public adjust: number = -1
    public adjustTime: number = -1

    public static create(adjust: number): AdjustConsume {
        let dur = new AdjustConsume()
        dur.adjust = adjust
        dur.adjustTime = Utils.getTimestamp()
        return dur
    }
}

class ChildTask {
    public name: string = ""
    public createTime: number = -1
    public doneTime: number = -1
    public order: number = -1
    public isDelay: boolean = false

    public static create(name: string): ChildTask {
        let dur = new ChildTask()
        dur.name = name
        dur.createTime = Utils.getTimestamp()
        return dur
    }

    public isDone(): boolean {
        return this.doneTime !== -1
    }

    public addOrder() {
        return this.order++
    }

    public downOrder() {
        return this.order--
    }

    public done() {
        this.doneTime = Utils.getTimestamp()
    }

    public reStart() {
        this.doneTime = -1
    }
}

class CycleRecord {
    dbId?: number
    public id: number = -1

    // 可以不断更新
    public _startTime: number[] = []
    // 单位天，其实不是gap，是 gap-1
    public _gap: number[] = []

    // ---- 模板
    public title: string = "undefine"
    public level: number = 3
    public expectConsumes: number = -1
    // ---- 模板

    public init(gap: number, title: string, level: number) {
        this.id = Utils.generateId()
        this.title = title
        this.level = level
        this._gap.push(gap)
        this._startTime.push(Utils.getTimestamp())
    }

    public getGap(): number {
        if (this._gap.length === 0) {
            return -1
        }
        return this._gap[this._gap.length - 1]
    }

    public setGap(gap: number) {
        this._gap.push(gap)
    }

    public getStartTime(): number {
        if (this._startTime.length === 0) {
            return -1
        }
        return this._startTime[this._startTime.length - 1]
    }

    public setStartTime(startTime: number) {
        this._startTime.push(startTime)
    }

    /**
     * 只要 currentTimeStamp 这天 0点-24点 在 starttime + n*gap 的这天 0点-24点，就创建任务
     * 创建的方式是找最新创建的一个任务，然后拷贝它的属性（这个有点复杂，要考虑没有最新创建任务的情况，暂时不这么实现，而是使用模板吧）
     */
    public isTimeToCreateTask(): boolean {
        let currentZero = DateUtils.getCurrentDayStamp(0)
        let startZero = DateUtils.getSomeDayStamp(this.getStartTime(), 0)
        return (currentZero - startZero) % (this.getGap() * 24 * 3600 * 1000) === 0
    }

    /**
     * 后来改成偏移 6小时
     * 有点乱，直接改成0-6点不刷新吧？好像不行，应该是 0-6 点会刷新昨天的
     */
    public isTimeToCreateTask2(): boolean {
        let currentZero = DateUtils.getMyCurrentDayStamp(0, DateUtils.dayOffset)
        let startZero = DateUtils.getSomeDayStamp(this.getStartTime(), 0)
        return (currentZero - startZero) % (this.getGap() * 24 * 3600 * 1000) === 0
    }

    public createTask(): Task {
        const newTask = new Task()
        newTask.init(`[周|${Format.formatTimeInDay2(DateUtils.getMyCurrentDayStamp(0, DateUtils.dayOffset))}] ${this.title}`)
        newTask.level = this.level
        newTask.expectConsumes.push(this.expectConsumes)
        newTask.cycleId = this.id
        newTask.expectStartTime = Utils.getTimestamp()
        return newTask
    }
}

class Note {
    public content: string = ""

    @Type(() => TimeLine)
    public timeLines: TimeLine[] = []

    public hasTimeLine(): boolean {
        return this.timeLines.length !== 0
    }

    public endTimeLine(): TimeLine | null {
        if (!this.hasTimeLine()) return null
        return this.timeLines[this.timeLines.length - 1]
    }

    /**
     * 在普通的timeline上合并 start end 数据
     */
    public getStartEndWrappedTimeLines(doingDurs: Duration[], childTasks: ChildTask[]): TimeLine[] {
        return doingDurs.map((dur) => {
            const lines: TimeLine[] = []
            if (dur.start > 0) {
                // lines.push(TimeLine.createContentNode2("开始任务", dur.start))
                lines.push(TimeLine.createStartEndAutoNode("└┈┈┈┘", dur.start))
                // lines.push(TimeLine.createContentNode2("┗━━━━━━━━━━━┛", dur.start))
                // lines.push(TimeLine.createContentNode2("┏━━━━━━━━━━━┓", dur.start))
                // lines.push(TimeLine.createContentNode2("┌┈┈┈┈┈┈┈┈┈┈┈┐", dur.start))
            }
            if (dur.end > 0) {
                // lines.push(TimeLine.createContentNode2("停下任务", dur.end))
                lines.push(TimeLine.createStartEndAutoNode("┌┈┈┈┐", dur.end))
            }
            return lines
        }).flatMap((lines) => lines).concat(this.timeLines).concat(
            childTasks.filter( childTask => childTask.isDone()).map(childTask => TimeLine.createChildTaskNode(`完成子任务：${childTask.name}`, childTask.doneTime))
        )
    }

    public getFormartTimeLines(doingDurs: Duration[], childTasks: ChildTask[]): TimeLine[] {
        const all = this.getStartEndWrappedTimeLines(doingDurs, childTasks)
            .filter((timeline) => !timeline.isDivder) // 旧数据适配
            .sort((a, b) => {
                if (a.timeStamp > b.timeStamp) {
                    return -1
                } else if (a.timeStamp < b.timeStamp) {
                    return 1
                } else {
                    return 0
                }
            })
        return TimeLine.addDivders(all)
    }
}

class TimeLine {
    public timeStamp: number = -1
    public isDivder: boolean = false
    public content: string = ""

    // 渲染用的中间字段
    public title: string = ""
    public isStartEndAutoGen: boolean = false
    public isChildTaskAutoGen: boolean = false

    public static createContentNode(content: string): TimeLine {
        const t = new TimeLine()
        t.timeStamp = Utils.getTimestamp()
        t.content = content
        return t
    }

    public static createStartEndAutoNode(content: string, timeStamp: number): TimeLine {
        const t = new TimeLine()
        t.timeStamp = timeStamp
        t.content = content
        t.isStartEndAutoGen = true
        return t
    }

    public static createDivNode(timeStamp: number): TimeLine {
        const t = new TimeLine()
        t.timeStamp = timeStamp
        t.isDivder = true
        return t
    }

    public static createChildTaskNode(content: string, timeStamp: number): TimeLine {
        const t = new TimeLine()
        t.timeStamp = timeStamp
        t.content = content
        t.isChildTaskAutoGen = true
        return t
    }

    public getTitled(title: string): TimeLine {
        const copy = Object.assign({}, this)
        // copy.content = `【${title}】${copy.content}`
        copy.title = title
        return copy
    }

    public getKey(): number {
        return this.timeStamp * (this.isDivder ? -1 : 1)
    }

    public static addDivders(src: TimeLine[]): TimeLine[] {
        if (src.length === 0) return []

        return DateUtils.getDurationCoveredDays(Duration.create(src[src.length - 1].timeStamp, src[0].timeStamp))
            .map((dayStamp) => {
                return TimeLine.createDivNode(dayStamp)
            })
            .concat(src)
            .sort((a, b) => {
                if (a.timeStamp > b.timeStamp) {
                    return -1
                } else if (a.timeStamp < b.timeStamp) {
                    return 1
                } else {
                    return 0
                }
            })
            // 去掉重复的div，这个倒叙顺序只留第一个就好
            .filter((timeline, index, array) => {
                if (timeline.isDivder) {
                    if (index == 0) {
                        // 不应该
                        return true
                    } else {
                        return !array[index - 1].isDivder
                    }
                } else {
                    return true
                }
            })
    }
}

class Config {
    public totalAdjustConsumeTime: number = 0

    // -1 是不存
    public autoSaveGapInHour: number = -1
    public lastAutoSaveTime: number = -1

    public isImported = false
}

/**
 * 内存数据 Model，
 */
class StoreModel {
    @Type(() => Task)
    public tasks: Task[] = []

    @Type(() => CycleRecord)
    public cycleRecords: CycleRecord[] = []

    public config = new Config()

    /**
     * 筛选规则：
     * 1. 所有 未完成 且 [预期开始时间在今天endtime之前](划掉) 的任务
     * 2. 今天完成的任务
     */
    public getTasks(dur: Duration, isNeedFuture: boolean = true): Task[] {
        const newTasks =  this.tasks.filter((value) => {
            if (isNeedFuture) {
                return (!value.isDone()) || dur.isIn(value.doneTime)
            } else {
                return (!value.isDone() && value.expectStartTime <= dur.end) || dur.isIn(value.doneTime)
            }
        }).filter(task => !task.isDeleted)

        return this.sortTasks(newTasks, dur)
    }

    public getCurrentDayDoingTasks(dur: Duration): Task[] {
        return this.tasks.filter((task) => {
            return task.doingDurs.find((taskDur) => {
                return dur.isIn(taskDur.start) // 这里对于跨天不是很友好
            }) !== undefined
                || task.adjusts.find((adjust) => {
                    return dur.isIn(adjust.adjustTime)
                }) !== undefined
        })
    }

    public addTask(task: Task): string {
        if (this.containsProject(task.id)) return "same id"
        if (this.tasks.find((t) => !t.isDone() && t.title === task.title) !== undefined) return "same title"
        this.tasks.push(task)
        return ""
    }

    public addCycleRecord(cycleRecord: CycleRecord) {
        if (this.containsCycleRecord(cycleRecord.id)) return
        this.cycleRecords.push(cycleRecord)
    }

    public invokeAddCycleTask() {
        this.cycleRecords.forEach(record => {
            if (record.isTimeToCreateTask2()) {
                const isNotCreated = this.tasks.filter((task) => {
                    const isSameId = task.cycleId === record.id
                    const isTodayTask = DateUtils.getMyCurrentDayDur(0).isIn(task.createTime)
                    // console.log(`isNotCreated, isSameId:${isSameId} isTodayTask:${isTodayTask}`)
                    return isSameId && isTodayTask
                }).length === 0
                if (isNotCreated) {
                    console.log(`create cycle task, record:${record.title}`)
                    const task = record.createTask()
                    this.addTask(task)
                    mainDB.saveTask(task)
  
                    // 点击任务为什么会添加到数据库，而且还添加好几次，之前应该就是这时候持久化导致卡顿的
                    // 这里没复现应该是太快了，我有点怀疑，因为存储是异步的，所以导致这个问题
                }
            }
        });
    }

    public removeTask(task: Task): boolean {
        if (!this.containsProject(task.id)) return false

        let length = this.tasks.length;
        for (let i = 0; i < length; i++) {
            if (this.tasks[i].id === task.id) {
                if (i === 0) {
                    this.tasks.shift(); //删除并返回数组的第一个元素
                } else if (i === length - 1) {
                    this.tasks.pop();  //删除并返回数组的最后一个元素
                } else {
                    this.tasks.splice(i, 1); //删除下标为i的元素
                }
                return true
            }
        }
        return false
    }

    private containsProject(id: number): boolean {
        return this.tasks.find(item => item.id === id) !== undefined
    }

    private containsCycleRecord(id: number): boolean {
        return this.cycleRecords.find(item => item.id === id) !== undefined
    }

    // 最好是定时刷新之类的
    public normalUpdate(currentDay: Duration) {
        this.invokeAddCycleTask()
        this.orderTasks(currentDay)

        // 直接this.invokeAutoDownload会有this问题，报错：TypeError: Converting circular structure to JSON
        setTimeout(() => this.invokeAutoDownload(), 0)
    }

    public invokeAutoDownload() {
        if (this.config.autoSaveGapInHour > 0) {
            if (Utils.getTimestamp() - this.config.lastAutoSaveTime > this.config.autoSaveGapInHour * 3600 * 1000) {
                this.download()
            }
        }
    }

    public download() {
        Utils.downloadModelFile(this)
        this.config.lastAutoSaveTime = Utils.getTimestamp()

        // 这个没有方法，只有状态，还是有问题，只好手动拿出来存了
        // const { saveConfig } = storeManager.getState('mainmodel') as { saveConfig: () => void};
        // saveConfig()
        localStorage.setItem('CONFIG_STORE_KEY', JSON.stringify(this.config))
    }

    // todo 应该是每次渲染的时候排序
    public orderTasks(currentDay: Duration) {
        
    }

    public sortTasks(tasks: Task[], currentDay: Duration): Task[] {
        return tasks.sort((a, b) => {
            // 完成的放最后
            if (a.isDone() && !b.isDone()) {
                return 1
            } else if (!a.isDone() && b.isDone()) {
                return -1
            } else {
                // 未来的任务也放后面
                if (a.isFutureTask(currentDay) && !b.isFutureTask(currentDay)) {
                    return 1
                } else if (!a.isFutureTask(currentDay) && b.isFutureTask(currentDay)) {
                    return -1
                } else {
                    // 正在做的放最前
                    if (a.isDoing()) {
                        return -1
                    } else if (b.isDoing()) {
                        return 1
                    } else {
                        // 到时间的放前面
                        if (a.isDangerous() && !b.isDangerous()) {
                            return -1
                        } else if (!a.isDangerous() && b.isDangerous()) {
                            return 1
                        } else {
                            // 然后比较level
                            if (a.level > b.level) {
                                return 1
                            } else if (a.level < b.level) {
                                return -1
                            } else {
                                // 同样 level 比较创建时间
                                if (a.createTime > b.createTime) {
                                    return -1
                                } else if (a.createTime < b.createTime) {
                                    return 1
                                } else {
                                    return 0
                                }
                            }
                        }
                    }
                }
            }
        })
    }
}


export { Task, Duration, Config, StoreModel, AdjustConsume, Note, CycleRecord, TimeLine, ChildTask }