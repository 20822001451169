import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input, message, Switch, Button, Row, Col, List, Dropdown, Menu, Modal, DatePicker } from 'antd';
// 这样居然真的行
import { ClickParam } from 'antd/lib/menu';
// import { DateType } from 'antd/lib/date-picker';
import { DownOutlined } from '@ant-design/icons';
import stores from '../stores';
import { StoreModel, Duration } from '../model/model'
import Utils from '../utils/Utils'
import DateUtils from '../utils/DateUtils'
import TaskInfoFormat from '../utils/TaskInfoFormat'
import Format from '../utils/Format'
import { Task, Note, CycleRecord } from '../model/model'
import { CurrentTaskKey } from '../stores/CurrentTaskStore';
import { CurrentDoingTaskKey } from '../stores/CurrentDoingTaskStore';
import { CurrentDayOffsetKey } from '../stores/CurrentDayOffsetStore';
import { SearchModeStoreKey } from '../stores/SearchModeStore';
import { SearchTextStoreKey } from '../stores/SearchTextStore';
import ConfigLayout from './ConfigLayout';
import 'antd/dist/antd.css';
import { plainToClass } from "class-transformer";
import moment from 'moment'

const Container = styled.div`
  /* background: #d1d3a2; */
  /* background: #d1dfff; */
  background: #282c34;
  height: 100vh;

  display: flex;
  flex-direction: column;
`

const Div = styled.div`
`

const ParamTag = styled.span`
  width:100px;
  display:inline-block;
  text-align:center;
`

const Container2 = styled.div`
  /* background: #d1d3a2; */
  /* background: #d1dfff; */
  background: #282c34;
  overflow:auto;
  /* height: 800px; */
  /* height: 83vh; */
  margin-top:10px;

  /* flex: 0 1 auto 居然效果一样 */
  flex: 1 1 auto;
`

const InputGroup = styled(Input.Group)`
margin: 1%;
`
const ButtonGroup = styled(Button.Group)`
margin: 1%;
`

const AlertTips = styled.span`
 padding: 4px;
background-color: ${(props: { isDangerous: boolean }) => {
    if (props.isDangerous) {
      return '#ff0000'
    } else {
      return '#ffffff'
    }
  }
  };
color: ${(props: { isDangerous: boolean }) => {
    if (props.isDangerous) {
      return '#ffff00'
    } else {
      return '#000000aa'
    }
  }
  };
  font-weight: ${(props: { isDangerous: boolean }) => {
    if (props.isDangerous) {
      return 'bold'
    } else {
      return 'normal'
    }
  }
  };
`

const TaskListContainer: React.FC = () => {
  const { storeModel, saveTask, saveCycleRecord } = stores.useStore('mainmodel') as { storeModel: StoreModel | undefined, saveTask: (task: Task) => void, saveCycleRecord: (cycleRecord: CycleRecord) => void }

  const { currentDayOffset, setCurrentDayOffset } = stores.useStore(CurrentDayOffsetKey) as { currentDayOffset: number, setCurrentDayOffset: (offset: number) => void }
  const { searchMode, setSearchMode } = stores.useStore(SearchModeStoreKey) as { searchMode: number, setSearchMode: (searchMode: number) => void }
  const { searchText, setSearchText } = stores.useStore(SearchTextStoreKey) as { searchText: string, setSearchText: (searchText: string) => void }
  const { currentTask, setCurrentTask } = stores.useStore(CurrentTaskKey) as { currentTask: Task | undefined, setCurrentTask: (note: Task | undefined) => void }

  const [title, setTitle] = useState("");
  const [searchTextUI, setSearchTextUI] = useState("");

  const [isNeedExceptTime, setNeedExceptTimeUI] = useState(true);
  const [expectTime, setExpectTime] = useState(2);

  const [level, setLevel] = useState(3);

  const [isCycleTask, setCycleTaskUI] = useState(false);
  const [cycleGap, setCycleGap] = useState(1);

  const [isLongTask, setLongTaskUI] = useState(false);
  const [exceptWorkday, setExceptWorkday] = useState(3);
  const [exceptHoliDay, setExceptHoliDay] = useState(1);

  const [isStartNow, setStartNow] = useState(true);
  const [expectStartTime, setExpectStartTime] = useState<any>(null);

  // React.useEffect(() => {
  //   console.log("TaskListContainer useEffect")
  // },[])

  function setNeedExceptTime(isNeedExceptTime: boolean) {
    if (!isNeedExceptTime && isLongTask) {
      message.error("长任务必须估时")
      return
    }

    setNeedExceptTimeUI(isNeedExceptTime)
  }

  function setCycleTask(isCycleTask: boolean) {
    if (isCycleTask) {
      setLongTask(false)
    }

    setCycleTaskUI(isCycleTask)
  }

  function setLongTask(isLongTask: boolean) {
    if (isLongTask) {
      setNeedExceptTime(true)
      setCycleTask(false)
    }

    setLongTaskUI(isLongTask)
  }

  function onAddTask(event: any) {
    event.preventDefault();

    if (storeModel === undefined) {
      message.error("storeModel not init onAddTask")
      return
    }

    if (Utils.isStringEmpty(title)) {
      message.error("请输入内容")
      return
    }
    if (isLongTask && isCycleTask) {
      message.error("不能同时是长任务和周期任务")
      return
    }

    if (isCycleTask) {
      const newRecord = new CycleRecord()
      newRecord.init(cycleGap, title, level)

      if (!isStartNow && expectStartTime !== null) {
        newRecord.setStartTime(expectStartTime.valueOf())
      }

      if (isNeedExceptTime) {
        newRecord.expectConsumes = expectTime * 3600 * 1000 // 单位小时，转换
      } else {
        newRecord.expectConsumes = -1 // 表示不设置时间
      }

      storeModel.addCycleRecord(newRecord)
      storeModel.normalUpdate(DateUtils.getMyCurrentDayDur(currentDayOffset))
      saveCycleRecord(newRecord)
    } else {
      const newTask = new Task()
      newTask.init(title)
      newTask.level = level

      if (!isStartNow && expectStartTime !== null) {
        newTask.expectStartTime = expectStartTime.valueOf()
      }

      if (isNeedExceptTime) {
        newTask.expectConsumes.push(expectTime * 3600 * 1000) // 单位小时，转换
      } else {
        newTask.expectConsumes.push(-1) // 表示不设置时间
      }

      const addResult = storeModel.addTask(newTask)
      if (!Utils.isStringEmpty(addResult)) {
        message.error(`添加失败 ${addResult}`)
        return
      }
      saveTask(newTask)

      storeModel.normalUpdate(DateUtils.getMyCurrentDayDur(currentDayOffset))
    }

    setTitle("")
    setExpectStartTime(null)
  }

  function changeOffsetDay(isAdd: boolean) {
    if (isAdd) {
      setCurrentDayOffset(currentDayOffset + 1)
    } else {
      setCurrentDayOffset(currentDayOffset - 1)
    }
  }

  

  function getOffsetString() {
    let base = "" + currentDayOffset
    if (currentDayOffset > 0) {
      base = "+" + base
    }
    return base
  }

  function getAllTaskStatus(): string {
    if (storeModel === undefined) {
      return ""
    }

    let notDoneTasks = storeModel.getTasks(DateUtils.getMyCurrentDayDur(currentDayOffset), false).filter((task) => { return !task.isDone() })

    if (notDoneTasks.length === 0) {
      return ""
    }

    let consumeFormat = ""
    let consumes: number = notDoneTasks.map((task) => {
      let left = task.getLeftExpectConsumeTime()
      if (left < 0) return 0
      return left
    }).reduce((a, b) => a + b)

    // console.log("getAllTaskStatus" + consumes)
    if (consumes > 0) {
      consumeFormat = `，总剩余预估需要花费${Format.formatDuration(consumes)}`
    }

    return `共${notDoneTasks.length}个任务未完成${consumeFormat}`
  }

  function getMenu(): React.ReactElement {
    return (
      <Menu onClick={(param) => setLevel(Number(param.key))}>
        <Menu.Item key="1">优先级 1</Menu.Item>
        <Menu.Item key="2">优先级 2</Menu.Item>
        <Menu.Item key="3">优先级 3</Menu.Item>
        <Menu.Item key="4">优先级 4</Menu.Item>
        <Menu.Item key="5">优先级 5</Menu.Item>
      </Menu>
    )
  }

  function search() {
    setSearchText(searchTextUI)
  }

  function switchSearchMode(searchModeLocal: number) {
    setCurrentTask(undefined)
    setSearchMode(searchModeLocal)
  }

  function isSearchMode(){
    return searchMode !== 0
  }

  return (
    <Container>

      <Div style={{ flex: "0 1 auto" }}>
        <ButtonGroup>
        <ConfigLayout/>
        <span style={{ paddingLeft: 10 }} ></span>
          <Button type="primary" size="small" onClick={(e) => changeOffsetDay(false)}>←</Button>

          <span style={{ backgroundColor: "#ffffff", padding: 4 }} >
            {Format.formatTimeInDay(DateUtils.getMyCurrentDayStamp(currentDayOffset, DateUtils.dayOffset))}({getOffsetString()})
        </span>

          <Button type="primary" size="small" onClick={(e) => changeOffsetDay(true)}>→</Button>
          <span style={{ paddingLeft: 10 }} ></span>
          
          {
            storeModel !== undefined &&
            <AlertTips isDangerous={storeModel.config.totalAdjustConsumeTime !== 0}>
              需平衡时间：{-storeModel.config.totalAdjustConsumeTime / (3600 * 1000)}h
        </AlertTips>
          }

          <Switch style={{ marginLeft: '10px', marginRight: '3px' }} checkedChildren="Search" unCheckedChildren="Normal" checked={isSearchMode()} onChange={(event) => switchSearchMode(event? 1: 0)} />

          {
          isSearchMode() &&
          <Switch checkedChildren="内容" unCheckedChildren="标题" checked={searchMode == 2} onChange={(event) => switchSearchMode(event ? 2 : 1)} />      
          }

        </ButtonGroup>

        {
          !isSearchMode() &&
          <div>
            <Row align="middle" style={{ margin: "10px" }}>
              <Col flex="1 1 auto">
                <Input placeholder="输入任务，回车添加" value={title} onChange={(event) => setTitle(event.target.value)} onPressEnter={onAddTask} />
              </Col>
              <Col flex="0 1 auto">

                <Dropdown overlay={getMenu()}>
                  <a style={{ paddingLeft: 5 }} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {formartLevel(level)} <DownOutlined />
                  </a>
                </Dropdown>

                {isNeedExceptTime &&
                  <Input style={{ width: '100px', marginLeft: "15px" }} value={expectTime} placeholder="花费时间" prefix="预计" suffix="h" onChange={(event) => setExpectTime(Number(event.target.value))} />
                }

                <Switch style={{ marginLeft: '5px', marginRight: '3px' }} checkedChildren="估时" unCheckedChildren="不限" checked={isNeedExceptTime} onChange={(event) => setNeedExceptTime(event)} />

                <Switch style={{ marginLeft: '5px', marginRight: '3px' }} checkedChildren="立刻开始" unCheckedChildren="之后开始" checked={isStartNow} onChange={(event) => setStartNow(event)} />

                {/* 长任务、短任务 */}
                {/* <Switch style={{ marginLeft: '5px', marginRight: '3px' }} checkedChildren="长" unCheckedChildren="短" checked={isLongTask} onChange={(event) => setLongTask(event)} /> */}

                <Switch style={{ marginLeft: '5px', marginRight: '3px' }} checkedChildren="周期" unCheckedChildren="单个" checked={isCycleTask} onChange={(event) => setCycleTask(event)} />

              </Col>
            </Row>

            <Div style={{ margin: "10px", color: "#ffffff" }}>

              {!isStartNow &&
                <span style={{ marginLeft: "15px" }} >开始时间：
        <DatePicker showTime value={expectStartTime} onOk={value => setExpectStartTime(moment(value.valueOf()))} /></span>
              }

              {isLongTask &&
                <span style={{ marginLeft: "15px" }} >长任务：
        <Input style={{ width: '100px' }} value={exceptWorkday} prefix="计划" suffix="天" onChange={(event) => setExceptWorkday(Number(event.target.value))} />
                  <Input style={{ width: '100px' }} value={exceptHoliDay} prefix="假期" suffix="天" onChange={(event) => setExceptHoliDay(Number(event.target.value))} />
                </span>
              }

              {isCycleTask &&
                <Input style={{ width: '100px', marginLeft: "15px" }} value={cycleGap} placeholder="1表示不间隔" prefix="每" suffix="天" onChange={(event) => setCycleGap(Number(event.target.value))} />
              }

            </Div>

            <span style={{ backgroundColor: "#ffffff", padding: 2, margin: "10px" }} >
              {getAllTaskStatus()}
            </span>
          </div>
        }

        {isSearchMode() &&
          <div style={{ marginLeft: '10px', marginRight: '10px' }}>
            <Input placeholder="回车进行搜索" value={searchTextUI} onChange={(event) => setSearchTextUI(event.target.value)} onPressEnter={search} />
          </div>
        }


      </Div>

      <NoteList />
    </Container>
  );
}


const NoteItem = styled.div`
:hover{
	/* background-color:#b4e7fc; */
  background-color: ${(props: { choosed: boolean, isDone: boolean, isFuture: boolean, level: number }) => {
    if (props.choosed) {
      return '#f5b52b'
    } else {
      return '#b4e7fc'
    }
  }
  }
};
background-color: ${(props: { choosed: boolean, isDone: boolean, isFuture: boolean, level: number }) => {
    if (props.choosed) {
      return '#f5b52b'
    } else {
      switch (props.level) {
        case 1:
          return '#fdf0ee'
        case 2:
          return '#fdfcee'
        case 3:
          return '#eefdfc'
        case 4:
          return '#eefdef'
        default:
          return '#ffffff'
      }
    }
  }
  };
opacity : ${(props: { choosed: boolean, isDone: boolean, isFuture: boolean, level: number }) => {
    if (props.isDone) {
      return 0.1
    } else if (props.isFuture) { // todo 之后再考虑这个 future UI怎么优化下
      return 0.5
    } else {
      return 1
    }
  }
  };
`
const NoteList: React.FC = () => {
  const { storeModel, saveTask, saveConfig } = stores.useStore('mainmodel') as { storeModel: StoreModel | undefined, saveTask: (task: Task) => void, saveConfig: () => void }

  const { currentTask, setCurrentTask } = stores.useStore(CurrentTaskKey) as { currentTask: Task | undefined, setCurrentTask: (note: Task | undefined) => void }
  const { currentDoingTask, setCurrentDoingTask } = stores.useStore(CurrentDoingTaskKey) as { currentDoingTask: Task | undefined, setCurrentDoingTask: (note: Task | undefined) => void }
  const { currentDayOffset, setCurrentDayOffset } = stores.useStore(CurrentDayOffsetKey) as { currentDayOffset: number, setCurrentDayOffset: (offset: number) => void }
  const { searchMode, setSearchMode } = stores.useStore(SearchModeStoreKey) as { searchMode: number, setSearchMode: (searchMode: number) => void }
  const { searchText, setSearchText } = stores.useStore(SearchTextStoreKey) as { searchText: string, setSearchText: (searchText: string) => void }

  useEffect(() => {
    // console.log("NoteList useEffect")

    if (storeModel!==undefined && (currentDoingTask === undefined || storeModel.config.isImported)) {
      let doingTasks = storeModel.tasks.filter((value) => { return value.isDoing() })

      // console.log(`XZY doingTasks ${doingTasks.length}`)
      if (doingTasks.length === 0) {
        return
      }

      if (doingTasks.length > 1) {
        message.error(`有 ${doingTasks.length} 个任务在运行中，自动关闭`)

        for (let i = 1; i < doingTasks.length; i++) {
          doingTasks[i].stop()
          saveTask(doingTasks[i])
        }
      }

      // console.log(`XZY setCurrentDoingTask ${doingTasks[0].title}`)
      setCurrentDoingTask(doingTasks[0])

      if(storeModel.config.isImported){
        storeModel.config.isImported = false
        saveConfig()
      }
    }
  });

  function onChooseNote(event: any, note: Task) {
    event.preventDefault()
    setCurrentTask(note)
  }

  const onClickMenu = (param: ClickParam, task: Task) => {
    task.level = Number(param.key)

    storeModel?.normalUpdate(DateUtils.getMyCurrentDayDur(currentDayOffset))
    saveTask(task)

    param.domEvent.preventDefault()

    // 怎么阻止对 parent 的点击事件
    // param.domEvent.stopPropagation()
  };

  function getMenu(task: Task): React.ReactElement {
    return (
      <Menu onClick={(param) => onClickMenu(param, task)}>
        <Menu.Item key="1">优先级 1</Menu.Item>
        <Menu.Item key="2">优先级 2</Menu.Item>
        <Menu.Item key="3">优先级 3</Menu.Item>
        <Menu.Item key="4">优先级 4</Menu.Item>
        <Menu.Item key="5">优先级 5</Menu.Item>
      </Menu>
    )
  }

  function startTask(event: any, task: Task) {
    if (task.isDone()) {
      message.error(`任务已完成，完成时间:${Format.formatTimeInMs(task.doneTime)}`)
      return
    }

    if (currentDoingTask != undefined && currentDoingTask.id === task.id) {
      message.error(`任务已经在进行中，上次开始时间：${Format.formatTimeInMs(task.lastStartTime())}`)
      return
    }

    if (task.isDoing()) {
      // todo 自动恢复
      message.error(`任务异常中断，重新开始。上次开始时间：${Format.formatTimeInMs(task.lastStartTime())}`)
    }

    if (currentDoingTask != undefined) {
      currentDoingTask.stop()
      saveTask(currentDoingTask)
    }

    task.start()
    setCurrentDoingTask(task)
    storeModel?.normalUpdate(DateUtils.getMyCurrentDayDur(currentDayOffset))
    saveTask(task)
  }

  function getShowTips(task: Task): string {
    let result = "  " + TaskInfoFormat.getDoingStatusTips(task) + TaskInfoFormat.getDeadline(task) + TaskInfoFormat.getConsumeExpected(task)
    return result
  }

  function getTasks(): Task[] {
    if (storeModel === undefined) {
      return []
    }

    if (searchMode !== 0) {
      if (Utils.isStringEmpty(searchText)) {
        return []
      }
      const lowSearchText = searchText.toLowerCase()
      return storeModel.tasks.filter((task) => {
        return task.title.toLowerCase().indexOf(lowSearchText) !== -1 

        // 匹配子任务标题
        || task.childTasks.find((childTask) => childTask.name.toLowerCase().indexOf(lowSearchText) !== -1 ) !== undefined

        // 匹配内容模式（时间线和note）
        || (searchMode == 2 && (
          task.note.timeLines.find(timeline => timeline.content.toLowerCase().indexOf(lowSearchText) !== -1 )!== undefined 
          || task.note.content.toLowerCase().indexOf(lowSearchText) !== -1
        ))
      })
    } else {
      return storeModel.getTasks(DateUtils.getMyCurrentDayDur(currentDayOffset))
    }
  }

  return (
    <Container2>
      <List
        size="small"
        style={{ backgroundColor: 'white', margin: "1%" }}
        bordered
        dataSource={getTasks()}
        renderItem={task => (
          <NoteItem choosed={task.isSameTask(currentTask)} key={task.id} onClick={(e) => onChooseNote(e, task)} isDone={task.isDone()} isFuture={task.isFutureTask(DateUtils.getMyCurrentDayDur(currentDayOffset))} onDoubleClick={(e) => startTask(e, task)} level={task.level}>

            <Row align="middle">
              <Col flex="1 1 auto">
                <NoteItemInput key={task.id} task={task} />
              </Col>
              <Col flex="0 1 auto" style={{ backgroundColor: "rgb(226, 226, 226)" }}>

                <span style={{ paddingLeft: "5px" }}>
                  {getShowTips(task)}
                </span>

                <Dropdown overlay={getMenu(task)}>
                  <a style={{ paddingLeft: 5 }} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {formartLevel(task.level)} <DownOutlined />
                  </a>
                </Dropdown>

                <Button style={{ marginLeft: "5px", marginRight: "2px" }} size="small" onClick={(e) => startTask(e, task)}>Start</Button>

              </Col>
            </Row>

          </NoteItem>
        )}
      />
    </Container2>
  );
}

function formartLevel(level: number): string {
  switch (level) {
    case 1:
      return "🌟"
    case 2:
      return "🎏"
    case 3:
      return "3️⃣"
    case 4:
      return "4️"
    case 5:
      return "5️"
    default:
      return ""
  }

  // switch (level) {
  //   case 1:
  //     return "🥇"
  //   case 2:
  //     return "🥈"
  //   case 3:
  //     return "🥉"
  //   case 4:
  //     return "4️⃣"
  //   case 5:
  //     return "5️"
  //   default:
  //     return ""
  // }
}

interface NoteItemInputProps {
  task: Task;
}

const NoteItemInput: React.FC<NoteItemInputProps> = (props) => {
  const [content, setContent] = useState(props.task.title)
    const {saveTask} = stores.useStore('mainmodel') as { saveTask: (task: Task) => void}


  function saveTaskTitle(event: any) {
    props.task.title = content

    // 试了下就1ms
    // const start = Utils.getTimestamp()
    saveTask(props.task)
    // console.log("TIME:" + (Utils.getTimestamp() - start))
  }

  return (
    <Input style={{ background: "#ffffff70", fontWeight: "bold" }} value={content} onChange={(e) => setContent(e.target.value)} onBlur={saveTaskTitle} />
  );
}

export default TaskListContainer;