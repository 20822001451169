import { Task, Config, StoreModel, CycleRecord } from '../model/model'
import Utils from '../utils/Utils';
import stores from '../stores';
import 'reflect-metadata'
import '../../node_modules/reflect-metadata/Reflect.js'
import { plainToClass } from "class-transformer";
import mainDB from "../db/MainDB";


const CONFIG_STORE_KEY = 'CONFIG_STORE_KEY'

/**
 * storeModel 管理器，也就是整个内存数据管理中心
 */
interface Model {
    storeModel: StoreModel | undefined,

    fetchData: () => void
    saveTask: (task: Task) => void
    saveCycleRecord: (task: CycleRecord) => void
    importModel:(model: StoreModel) => void
    _onInitFinish:(model: StoreModel) => void

    saveConfig: () => void
}
const mainModel: Model = {
    // todo 这里异步初始化不知道怎么处理更好
    storeModel: undefined,
    fetchData() {
        if (this.storeModel === undefined) {
            this.storeModel = new StoreModel()

            let storageJson = localStorage.getItem(CONFIG_STORE_KEY)
            if (storageJson != null) {
                try {
                    this.storeModel.config = plainToClass(Config, JSON.parse(storageJson))
                } catch (error) {
                    console.error("CONFIG_STORE_KEY RESTORE RERROR:" + storageJson)
                }
            }

            // todo 这里其实适合两个 promise，但是暂时先不改
            mainDB.fetchAllTasks(tasks => {
                if (this.storeModel !== undefined) {
                    this.storeModel.tasks = tasks
                    this._onInitFinish(this.storeModel)
                }
            })
            mainDB.fetchAllCycleRecord(tasks => {
                if (this.storeModel !== undefined) {
                    this.storeModel.cycleRecords = tasks
                    this._onInitFinish(this.storeModel)
                }
            })
        }
    },
    saveTask(task: Task) {
        // todo 是否要更新 storeModel
        mainDB.saveTask(task)
    },
    saveCycleRecord(task: CycleRecord) {
        mainDB.saveCycleRecord(task)
    },
    importModel(model: StoreModel){
        this.storeModel = model
        mainDB.deleteAll()
        this.storeModel.tasks.forEach(task => mainDB.saveTask(task))
        this.storeModel.cycleRecords.forEach(record => mainDB.saveCycleRecord(record))
        this.storeModel.config.isImported = true
        this.saveConfig()
    },
    // 用来刷新 storeModel 的监听 view
    _onInitFinish(model: StoreModel){
        this.storeModel = model
    },
    saveConfig() {
        localStorage.setItem(CONFIG_STORE_KEY, JSON.stringify(this.storeModel?.config))
    },
};

export default mainModel