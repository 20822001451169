import 'reflect-metadata'
import React, { useState, ReactElement } from 'react';
import styled from 'styled-components';
import stores from '../stores';
import { List, Radio } from 'antd';
import { StoreModel, Task, Duration, TimeLine } from '../model/model'
import DateUtils from '../utils/DateUtils'
import Format from '../utils/Format'

const Div = styled.div`
height: 55vh;
overflow-y: scroll;
white-space: pre-wrap;
background-color:#ffffff;
/* background-color:rgb(227, 233, 231); */

::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .5);
            box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
`

const NoteItem = styled.div`
padding-top:1px;
padding-bottom:1px;
padding-left:2px;
padding-right:2px;
:hover{
background-color: #a2e3cd;
};
background-color:#e3e9e7;
font-size:11px;
`

const TimeLineLayout: React.FC = () => {
  const { storeModel } = stores.useStore('mainmodel') as { storeModel: StoreModel | undefined };
  const [timelineSize, setTimelineSize] = useState(200);

  // React.useEffect(() => {
  //   console.log("app useEffect")
  // },[])


  // function getCurrentDayDoingTasksTimelines(): TimeLine[] {
  //   return storeModel.getTasks(DateUtils.getMyCurrentDayDur(0))
  //     .flatMap((task) => task.note.getStartEndWrappedTimeLines(task.doingDurs).map((timeline) => {
  //       return timeline.getTitled(task.title)
  //     })).sort((a, b) => {
  //       if (a.timeStamp > b.timeStamp) {
  //         return -1
  //       } else if (a.timeStamp < b.timeStamp) {
  //         return 1
  //       } else {
  //         return 0
  //       }
  //     }
  //     ).filter((timeline) => !timeline.isDivder)
  // }

  function getCurrentDayDoingTasksTimelines(): TimeLine[] {
    if (storeModel === undefined) {
      return []
    }

    const all = storeModel.tasks
      .flatMap((task) => task.note.getStartEndWrappedTimeLines(task.doingDurs, task.childTasks).map((timeline) => {
        return timeline.getTitled(task.title)
      }))
      .filter((timeline) => !timeline.isDivder)
      .sort((a, b) => {
        if (a.timeStamp > b.timeStamp) {
          return -1
        } else if (a.timeStamp < b.timeStamp) {
          return 1
        } else {
          return 0
        }
      })
      .slice(0, timelineSize)

    return TimeLine.addDivders(all)
  }

  function getItem(timeline: TimeLine): JSX.Element {
    if (timeline.isDivder) {
      return <span style={{ backgroundColor: "#f9e6ba", color: "#ce9100" }}>{`┗━ ${Format.formatTimeInDay(timeline.timeStamp)} ━━━━`}</span>
    } else if (timeline.isChildTaskAutoGen) {
      return <span>
        <span style={{ color: "#ce9100", paddingRight: "2px" }}>{Format.formatTimeInSecond(timeline.timeStamp)}</span>
        <span style={{ color: "#229a72", display: "inline-block", verticalAlign: "top", maxWidth: "110px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: "3px" }}>  {timeline.title} </span>
        <span style={{ color: "#333333", fontWeight: "bold"}}>  {timeline.content} </span>
      </span>
    } else {
      return <span>
        <span style={{ color: "#ce9100", paddingRight: "2px" }}>{Format.formatTimeInSecond(timeline.timeStamp)}</span>
        <span style={{ color: "#229a72", display: "inline-block", verticalAlign: "top", maxWidth: "110px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: "3px" }}>  {timeline.title} </span>
        {timeline.content}
      </span>
    }
  }

  return (
    <Div>
      <Radio.Group onChange={(event) => setTimelineSize(Number(event.target.value))} defaultValue="200" size="small">
        <Radio.Button value="200">200</Radio.Button>
        <Radio.Button value="400">400</Radio.Button>
        <Radio.Button value="600">600</Radio.Button>
        <Radio.Button value="800">800</Radio.Button>
        <Radio.Button value="1000">1000</Radio.Button>
      </Radio.Group>

      {/* // todo 下面这个 key 不能用 getKey() 非常奇怪 */}
      <List
        size="small"
        bordered
        dataSource={getCurrentDayDoingTasksTimelines()}
        renderItem={timeline => (
          <NoteItem key={timeline.timeStamp}>
            {
              getItem(timeline)
            }
          </NoteItem>
        )}
      />
    </Div>
  );
}

export default TimeLineLayout;
